.post-screen {
    &__container {
        width: 60%;
        margin: 0 auto;
        font-size: 1.25rem;
    }
    &__tags {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 0;
        .ant-tag {
            padding: 0 1rem;
            font-size: 1.25rem;
            height: 44px;
            line-height: 40px;
        }
    }
}