.layout-website {
    &__header {
        clip-path: polygon(100% 0px, 0px 0px, 0px 70%, 50% 100%, 100% 70%);
        height: 300px;
        margin-bottom: 1.5rem;
        position: relative;
        background: linear-gradient(90deg, #9f5d6a, #cc626c 25.87%, #fabf4e);
        color: #fff;
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 2rem;
            >a {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                color: #FFF;
                svg {
                    margin-right: 1rem;
                }
            }
            nav {
                a {
                    margin: 0 1rem;
                    font-size: 1.25rem;
                    color: #fff;
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }
        &-middle {
            text-align: center;
            h1 {
                font-size: 2.5rem;
                font-weight: bold;
                color: #fff;
            }
            p {
                font-size: 1.5rem;
            }
        }
    }
}