.boardingPassComponent {
    >header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        h1 {
            display: inline-block;
            position: relative;
            padding-bottom: 0.5rem;
            font-family: "BebasNeue";
            font-size: 5rem;
            letter-spacing: 0.75rem;
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                height: 0.75rem;
                width: 100%;
                background-image: url("../../assets/img/boarding-pass/picto-triangle.png");
                background-size: 1.5rem;
            }
        }
        h2 {
            font-family: "Korb";
            font-weight: 400;
            font-size: 2.5rem;
            letter-spacing: 0.75rem;
        }
    }
    >main {
        display: flex;
        justify-content: center;
        section {
            &:nth-child(1) {
                width: 28%;
                margin-left: 2%;
            }
            &:nth-child(2) {
                width: 48%;
            }
            &:nth-child(3) {
                width: 20%;
                margin-right: 2%;
            }
        }
    }
    .block {
        &--borderRight {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-image: linear-gradient( to bottom, transparent 50%, #223049 50%);
                background-size: 100% 0.5rem;
            }
            >div {
                padding: 1.5rem;
            }
        }
        &--borderBottom {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 1px;
                background-image: linear-gradient( to right, transparent 50%, #223049 50%);
                background-size: 0.5rem 100%;
            }
        }
    }
    .blockWelcome {
        padding: 1.5rem;
        text-align: center;
        p {
            margin: 0;
        }
        &__title {
            font-family: "din-condensed", sans-serif;
            text-transform: uppercase;
            font-size: 1.5rem;
            line-height: 2rem;
        }
        &__subtitle {
            font-family: "BebasNeue";
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 3.5rem;
        }
    }
    .blockExpertise {
        &-title {
            position: relative;
            font-family: "BebasNeueLight";
            text-align: center;
            font-size: 1.75rem;
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                width: 5rem;
                background-image: url("../../assets/img/boarding-pass/arrow.png");
                background-size: 75%;
                background-repeat: no-repeat;
                background-position: center;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
                transform: rotate(180deg);
            }
        }
        &-list {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: center;
            font-family: "BebasNeue";
            font-size: 1.5rem;
            li {
                padding: 0.5rem 0 1rem;
                background-image: url("../../assets/img/boarding-pass/picto-space-invader.png");
                background-size: 0.75rem;
                background-repeat: no-repeat;
                background-position: center bottom;
            }
        }
    }
    .blockEventInfo {
        display: flex;
        align-items: center;
        >img {
            width: 25%;
        }
        >div {
            margin-left: 1.5rem;
            p {
                font-family: "BebasNeue";
                font-weight: bold;
                font-size: 1.5rem;
                span {
                    font-family: "Courier New";
                    font-size: 1.25rem;
                    font-weight: normal;
                }
            }
        }
    }
    .blockEcusson {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0 4rem;
        >img {
            width: 50%;
        }
    }
    .blockStandInfo {
        text-align: center;
        &__title {
            font-family: "din-condensed", sans-serif;
            text-transform: uppercase;
            font-size: 1.25rem;
        }
        >img {
            width: 35%;
        }
        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                padding: 0 1.5rem;
                font-family: "din-condensed", sans-serif;
                font-size: 1.25rem;
                text-align: center;
                img {
                    display: block;
                    margin: 0 auto 0.25rem;
                    width: 50%;
                }
                span {
                    display: block;
                    font-weight: bold;
                    font-size: 1.5rem;
                }
            }
        }
    }
    form {
        text-align: center;
        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1rem 0;
            label {
                font-family: "BebasNeue";
                font-weight: bold;
                font-size: 1.5rem;
                width: 35%;
                text-align: left;
                color: rgba(0, 0, 0, 0.65);
            }
        }
        button {
            border: 1px solid #ddd;
            padding: 1rem 1.5rem;
            border-radius: 3px;
            text-transform: uppercase;
            font-family: "BebasNeue";
            font-size: 1.25rem;
        }
    }
}