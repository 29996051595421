@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

@import "~normalize.css";
@import url("https://fonts.googleapis.com/css?family=Work+Sans");

@font-face {
  font-family: "Korb";
  src: url("./assets/fonts/Korb/Korb.woff2") format("woff2"),
    url("./assets/fonts/Korb/Korb.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "BebasNeue";
  src: url("./assets/fonts/BebasNeue/BebasNeueRegular.eot");
  src: url("./assets/fonts/BebasNeue/BebasNeueRegular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BebasNeue/BebasNeueRegular.woff2") format("woff2"),
    url("./assets/fonts/BebasNeue/BebasNeueRegular.woff") format("woff"),
    url("./assets/fonts/BebasNeue/BebasNeueRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BebasNeueLight";
  src: url("./assets/fonts/BebasNeue/BebasNeueLight.eot");
  src: url("./assets/fonts/BebasNeue/BebasNeueLight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BebasNeue/BebasNeueLight.woff2") format("woff2"),
    url("./assets/fonts/BebasNeue/BebasNeueLight.woff") format("woff"),
    url("./assets/fonts/BebasNeue/BebasNeueLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    background-color: #f0f2f5;
}

