.form {
    &__radio-field {
        $self: &;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        &__radio {
            position: relative;
            width: 1rem;
            height: 1rem;
            border: 1px solid #d9d9d9;
            border-radius: 100px;
            background-color: #fff;
            transition: all .3s;
            >input {
                opacity: 0;
            }
        }
        &__label {
            padding: 0.5rem;
            font-size: 15px;
            font-weight: 500;
            color: #3e4676;
        }
        &:hover {
            #{ $self }__radio {
                border-color: #3e4676;
            }
        }
        &--checked {
            #{ $self }__radio {
                background-color: #3e4676;
                border-color: #3e4676;
                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0.25rem;
                    height: 0.5rem;
                    margin: 0.15rem 0.3rem;
                    transform: rotate(45deg) scale(1);
                    border: 2px solid #fff;
                    border-top: 0;
                    border-left: 0;
                    content: '';
                }
            }
        }
    }
}