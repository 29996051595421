.admin-layout {
  &__header {
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 200px);
    &-toggle-menu {
      font-size: 18px;
      line-height: 64px;
      cursor: pointer;
      color: #fff;
    }
  }
  &__sider {
    position: fixed;
    height: 100vh;
    &-logo {
      height: 60px;
      line-height: 60px;
      padding: 0 0 0 16px;
      border-radius: 3px;
      font-size: 1.2rem;
      color: #fff;
    }
  }
  &__header-menu {
    width: 192px;
    margin: auto 0;
    .ant-menu-submenu-title {
      height: 64px !important;
      margin: 0;
      line-height: 64px !important;
    }
    .ant-menu-sub {
      .ant-menu-item {
        padding-left: 24px !important;
      }
    }
  }
  &__container {
    margin-left: 200px;
  }
  &__content {
    margin-top: 64px;
    padding: 24px;
  }
}
