.not-found-page {
    position: relative;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #9f5d6a, #cc626c 25.87%, #fabf4e);
    &__content {
        position: relative;
        z-index: 2;
        padding: 2rem;
        border-radius: 3px;
        color: #FFF;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        p {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            font-size: 1.5rem;
        }
    }
    &__title {
        font-size: 2rem;
        margin: 0 0.5rem;
    }
    &__subtitle {
        margin: 0 0.5rem;
    }
    &__background {
        position: absolute;
        height: 100vh;
        width: 100vw;
        background-position: bottom center;
        background-size: 150vh;
        background-repeat: no-repeat;
        background-image: url(../../assets/img/donut-factory-background.png);
    }
}