.screen-auth {
    position: relative;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #9f5d6a, #cc626c 25.87%, #fabf4e);
    &__container {
        position: relative;
        z-index: 2;
        width: 360px;
        padding: 1.5rem;
        border-radius: 3px;
        color: #FFF;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        svg {
            margin-right: 0.5rem;
        }
    }
    &__form {
        margin-top: 1.5rem;
        button {
            text-transform: uppercase;
            &:hover, &:focus {
                color: #000;
                background-color: #FFF !important;
                border-color: #FFF;
            }
        }
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
        &--center {
            justify-content: center;
        }
        a {
            color: #FFF;
        }
    }
    &__background {
        position: absolute;
        height: 100vh;
        width: 100vw;
        background-position: bottom center;
        background-size: 150vh;
        background-repeat: no-repeat;
        background-image: url(../../assets/img/donut-factory-background.png);
    }
}